@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;900&display=swap");

#header {
  padding: 0!important;
}

.sidebar-wrapper-scroller::-webkit-scrollbar-thumb {
  background-color: #ff5233;
  /* Change this to your desired scrollbar thumb color */
  border-radius: 10px;
}

/* Scrollbar track (the background) */
.sidebar-wrapper-scroller::-webkit-scrollbar-track {
  background-color: transparent;
  /* Change this to your desired scrollbar track color */
}

/* Optional: Adjust scrollbar width and height */
.sidebar-wrapper-scroller::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

select {
  font-family: "Roboto", sans-serif;
}

body input[type="text"],
body input[type="email"],
input[type="number"],
input[type="date"],
input[type="password"],
input[type="search"],
textarea {
  font-size: 12px !important;
}

body input[type="text"]:focus,
body input[type="email"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
input[type="date"]:focus,
input[type="search"]:focus,
textarea:focus {
  font-size: 12px !important;
}

body input[type="text"]::placeholder,
body input[type="email"]::placeholder,
body input[type="date"]::placeholder,
input[type="number"]::placeholder,
input[type="password"]::placeholder,
input[type="search"]::placeholder,
textarea::placeholder {
  font-size: 12px !important;
}


.checkoutForm input[type="text"]::placeholder,
.checkoutForm input[type="email"]::placeholder {
  font-size: 14px !important;
}





select {
  font-size: 12px !important;
}

.multiselect-container input.searchBox {
  opacity: 0;
}

@media (max-width: 990px) {
  .tempofeature_dot {
    list-style: none;
  }

  .tempofeature_dot li p::before {
    content: "•";
    margin-right: 6px;
  }

  body select:focus {
    font-size: 16px !important;
  }

  select {
    font-size: 16px !important;
  }

  body input[type="date"] {
    display: table;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    box-sizing: border-box;
    min-height: 1.3rem !important;
  }

  body input[type="text"],
  body input[type="number"],
  body input[type="email"],
  body input[type="date"],
  body input[type="search"],
  body input[type="password"],
  body textarea {
    font-size: 16px !important;
  }

  body select,
  body textarea,
  body input {
    font-size: 16px !important;
  }

  body input[type="text"]:focus,
  body input[type="number"]:focus,
  body input[type="date"]:focus,
  body input[type="email"]:focus,
  body input[type="password"]:focus,
  body input[type="search"]:focus,
  body textarea:focus {
    font-size: 16px !important;
  }

  body input[type="text"]::placeholder,
  body input[type="number"]::placeholder,
  body input[type="email"]::placeholder,
  body input[type="password"]::placeholder,
  body input[type="date"]::placeholder,
  body input[type="search "]::placeholder,
  textarea::placeholder {
    font-size: 12px !important;
  }
}

body .hide-focus {
  outline: unset !important;
  border: white !important;
}

h2,
h3 {
  /* font-family: 'Cairo', sans-serif; */
  font-family: "Sohne", sans-serif;

  font-weight: 450 !important;
}

div,
p,
h1 {
  font-family: "Sohne", sans-serif;
}

.img-div-1:hover {
  filter: brightness(0) saturate(78%) invert(77%) sepia(282%) saturate(707%)
    hue-rotate(510deg) brightness(51%) contrast(196%);
}

.img-platform {
  width: 65px;
  height: 65px;
  object-fit: contain;
}

.img-platform-1 {
  width: 90px !important;
  height: 74px;
  object-fit: contain;
}

.img-platform-whatsapp {
  width: 90px;
  height: 80px;
}

.rotate-180 {
  transition-timing-function: ease;
  transition-duration: 0.125s;
  transition-delay: 0.1s;
}

pre code.hljs {
  padding: 0 !important;
}

span.hljs-tag {
  font-size: 13px;
}

.img-div-2 {
  display: flex;
  align-items: center;
  width: 140px;
}

.img-div-2 img {
  object-fit: contain;
}

/* for icon_animation */
.animation_icon_section .col_section {
  padding: 184px 0px;
}

.leftimg_sec.animation2 {
  animation: shake 15s infinite;
}

.leftimg_sec.animation {
  animation: shake2 15s infinite;
}

.leftimg_sec.animation3 {
  animation: shake3 15s infinite;
}

@keyframes shake {
  0%,
  100% {
    transform: translateY(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateY(-5px);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translateY(5px);
  }
}

@keyframes shake2 {
  0%,
  100% {
    transform: translateY(0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translateY(-5px);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateY(5px);
  }
}

@keyframes shake3 {
  0%,
  100% {
    transform: translateY(0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translateY(-3px);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateY(3px);
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (max-width: 768px) {
  body .history_logs_wrapper img {
    width: 100% !important;
    height: auto !important;
  }

  .img-div-2 {
    width: 120px;
  }

  .img-platform {
    width: 45px;
    height: 45px;
    object-fit: contain;
  }

  .img-platform-1 {
    width: 45px;
    height: 45px;
    object-fit: contain;
  }

  .animation_icon_section .col_section.content {
    padding: 0px 0px !important;
  }

  .animation_icon_section .col_section {
    padding: 75px 0px;
  }
}

pre {
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.my-side-hover {
  position: absolute;
  z-index: 9;
  top: -21px;
  width: 100%;
  left: 250px;
}

.selectdiv {
  position: relative;
}

select.custom-select {
  background-color: #ffffff;
  color: #000000;
  -webkit-appearance: none;
  padding: 8px;
  border-radius: 4px;
  outline: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  outline: none;
  background-repeat: no-repeat;
  font-weight: 500;
  background-position: right;
}

select.upper-select {
  background-color: #ffffff;
  color: #000000;
  -webkit-appearance: none;
  padding: 8px;
  border-radius: 4px;
  outline: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  outline: none;
  background-repeat: no-repeat;
  font-weight: 500;
  background-position: right;
}

body .new_fade {
  color: #c7c6c7 !important;
  font-weight: 500;
}

body .searchWrapper {
  background-color: #ffffff;
  /* Set your desired background color */
  color: #000000;
  /* Set the text color */
  -webkit-appearance: none;
  /* Override Safari's default styling */
  padding: 6px;
  /* Adjust the padding as needed */
  border-radius: 4px;
  outline: none;
  /* Remove the default focus outline */
  /* background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>"); */
  outline: none;
  background-repeat: no-repeat;
  background-position: right;
  width: 150px;
}
body .custom-multselect .searchWrapper {
  width: 100%;
  display: flex;
  gap: 1rem;
}

body .searchWrapper-live .searchWrapper {
  background-color: #ffffff;
  /* Set your desired background color */
  color: #000000;
  /* Set the text color */
  -webkit-appearance: none;
  /* Override Safari's default styling */
  padding: 6px;
  /* Adjust the padding as needed */
  border-radius: 4px;
  outline: none;
  /* Remove the default focus outline */
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  outline: none;
  background-repeat: no-repeat;
  background-position: right;
  width: 100%;
}

body .chip {
  font-size: 10px;
  line-height: 16px;
  margin-bottom: 4px;
  font-weight: 700;
  margin-right: 0;
  background-color: #80808024;
  word-wrap: break-word;
  display: flex;
  justify-content: space-between;
  width: 88%;
  padding: 7px 7px;
  text-wrap: wrap;
  white-space: normal;
  border-radius: 4px;
  color: #000;
}
body .custom-multselect .searchWrappery .chip {
  font-size: 10px;
  line-height: 16px;
  margin-bottom: 4px;
  font-weight: 700;
  margin-right: 0;
  background-color: #80808024;
  word-wrap: break-word;
  display: flex;
  justify-content: space-between;
  width: 70%;
  padding: 7px 7px;
  text-wrap: wrap;
  white-space: normal;
}
body .custom-multselect .searchWrapper .chip {
  font-size: 10px;
  line-height: 16px;
  margin-bottom: 0px !important;
  font-weight: 400;
  margin-right: 0;
  /* background: #80808024  !important; */
  background-color: #2563ebbf;
  word-wrap: break-word;
  display: flex;
  justify-content: space-between;
  width: 16%;
  padding: 7px 7px;
  text-wrap: wrap;
  white-space: normal;
}

body .highlightOption {
  background-color: #0057ff;
  font-size: 10px;
}

body .multiSelectContainer ul {
  font-size: 10px;
}

select.custom-select::-ms-expand {
  display: none;
}

.singleblog ol {
  list-style: auto !important;
}

.chat-scroll div::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.chat-scroll div::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
.chat-scroll div::-webkit-scrollbar-thumb {
  background: rgb(18, 17, 66);
  border-radius: 10px;
}

/* Handle on hover */
.chat-scroll div::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* timer animation */
}

.rainbow {
  align-items: center;
  background-color: #fff;
  border: 1px solid #8e9297;
  border-radius: 500px;
  color: var(--glue-grey-700);
  display: flex;
  font-weight: 500;
  height: 52px;
  justify-content: center;
  padding: 0 24px;
  position: relative;
  white-space: nowrap;
  max-width: 321px;
}

.facet-pill-border {
  fill: none;
  border-radius: 500px;
  height: 52px;
  left: -1px;
  pointer-events: none;
  position: absolute;
  top: -1px;
  width: calc(100% + 2px);
}

.facet-pill-border rect {
  stroke: #1e293b;
  stroke-width: 4px;
  height: 52px;
  visibility: hidden;
  width: 100%;
  animation: opacityChange 12s linear;
}

@keyframes opacityChange {
  0% {
    stroke-dasharray: 400.373;
  }

  100% {
    stroke-dasharray: 920.373;
  }
}

.div-anima .bg-black + .rainbow .facet-pill-border rect,
.start-rainbow .facet-pill-border rect {
  visibility: visible !important;
}

.tabs.overflow-x-scroll::-webkit-scrollbar {
  display: none;
}

@media (max-width: 768px) {
  .img-platform-whatsapp {
    height: 55px;
  }

  .facet-pill-border rect {
    animation: opacityChange 12s linear;
  }

  .accordion__item:nth-child(2) .mobile_arroww .accordion__button:before,
  .accordion__item:nth-child(3) .mobile_arroww .accordion__button:before {
    display: none;
  }

  .active.start-rainbow {
    order: -2;
  }

  .border-animation {
    order: -1;
  }

  .trytempo a {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

select.custom-select {
  /* height: 45px !important; */
  background-color: #fff;
  margin-top: -7px;
}

select.custom-select:focus {
  border: 1px solid grey !important;
}

.trytempo a {
  padding: 2rem;
}

.calendly-popup iframe {
  height: 105% !important;
}

.fade-in-image {
  animation: fadeIn 3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider:before {
  left: -3px;
}

.active-class::before {
  border-top: 3px solid #1a73e8;
  bottom: 0;
  content: "";
  display: block;
  height: 0;
  left: -36px;
  position: absolute;
  width: 100%;
  z-index: 0;
}

input:checked + .slider {
  background-color: #2563eb;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
  display: none;
}

.myparent input:hover + label {
  text-decoration: underline;
}

.desk_headermenupopup {
  display: none !important;
}

.top-0::-webkit-scrollbar {
  width: 0px;
}

.border-class {
  border: 2px solid #7ec1ec !important;
}

/* accordion tabs */

.tab_accordion_heading.active:before {
  position: absolute !important;
  right: 0 !important;
  top: 28px !important;
  display: inline-block;
  content: "";
  height: 10px;
  width: 10px;
  margin-right: 23px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(45deg) !important;
  color: #fff !important;
  transition: transform 0.5s ease-in-out;
}

.tab_accordion_heading.start-rainbow:before {
  position: absolute !important;
  right: 0 !important;
  top: 28px !important;
  display: inline-block;
  content: "";
  height: 10px;
  width: 10px;
  margin-right: 23px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
  color: #000;
  transition: transform 0.5s ease-in-out;
}

/* .dfdasdasd{
transition: all .4s ease;
} */
.accordionitem_panel {
  display: block !important;
  transition: transform 5s ease-in-out;
}

.accordionitem_panel .p-6 {
  min-height: 0;
  height: 0;
  overflow: hidden;
  transition: transform 5s ease-in-out;
}

.bg-background.rounded-lg .accordionitem_panel .p-6 {
  height: auto;
  transition: transform 5s ease-in-out;
}

.swiper-pagination {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.featurecards {
  padding: 1rem;
}

.featurecards:hover {
  /* border: 1px solid #000; */
  /* border-color: #09162A; */
  box-shadow: 0px 2px 10px 1px #09162a, 0 6px 7px -5px #09162a;
}

.featurecards_mobile:after {
  position: absolute !important;
  right: -20px !important;
  top: 15px !important;
  display: inline-block;
  content: "";
  height: 8px;
  width: 8px;
  margin-right: 23px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
  color: #000;
  transition: transform 0.5s ease-in-out;
}

.featurecards_mobile2:after {
  position: absolute !important;
  right: -20px !important;
  top: 15px !important;
  display: inline-block;
  content: "";
  height: 8px;
  width: 8px;
  margin-right: 23px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(45deg);
  color: #000;
  transition: transform 0.5s ease-in-out;
}

.workflowslider button.slick-arrow.slick-prev::before,
.workflowslider button.slick-arrow.slick-next::before {
  display: none;
}

.workflowslider button.slick-arrow.slick-prev {
  background-image: url("../public/workflowslider/left.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 65px;
  height: 65px;
  top: 0px;
  left: unset !important;
  right: 45px;
  z-index: 1;
}

.workflowslider button.slick-arrow.slick-next {
  background-image: url("../public/workflowslider/right.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 65px;
  top: 0px;
  height: 65px;
  right: -20px;
}

/* my csss */
/* .workflowslider li.slick-active {
  opacity: 1 !important;
  width: 20%;
  background: #97a0af;
  border-radius: 40px;
}
.workflowslider .slick-dots li { 
  opacity: 0;
  width: 13.8%;
  border-radius: 40px;
}
.workflowslider .slick-dots li.slick-active button:before {
  opacity: 0 ;
}
.workflowslider  .slick-dots li button { 
  width: 33px;
  height: 33px; 
}
.workflowslider ul.slick-dots {
  background: #ececed6e;
  border-radius: 40px;    width: 92%;
  left: 17px;
} */

.rdw-editor-toolbar {
  margin-bottom: 0px !important;
  border: 1px solid rgb(192, 192, 192) !important;
  border-bottom: 0px !important;
  border-radius: 7px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.rdw-editor-toolbar {
  margin-bottom: 0px !important;
  border: 1px solid rgb(192, 192, 192) !important;
  border-bottom: 0px !important;
  border-radius: 7px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

/* .learnmore_integration_btn {
  height: 0;
  transition: transform 400ms ease 0s;
}
.integration_cards:hover .learnmore_integration_btn {
  height: auto;
  transition: transform 400ms ease 0s;
} */

.new_input {
  border-radius: 4px;
  padding: 8px;
  outline: none;
  font-weight: 500;
  border: 1px solid #c7c6c7;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  transition: none;
  text-overflow: ellipsis;
}

.new_input::placeholder {
  color: #c7c6c7;
}

.new_input:focus {
  /* box-shadow: none !important; */
  border: 1px solid rgb(103, 124, 157) !important;
  border-radius: 4px !important;
  padding: 8px !important;
  outline: none !important;
  font-weight: 500 !important;
  box-sizing: border-box !important;
  width: 100% !important;
  position: relative !important;
}

.new_input_phone {
  border-radius: 4px;
  padding: 8px;
  outline: none;
  font-weight: 500;
  border: 1px solid #c7c6c7;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  transition: none;
  text-overflow: ellipsis;
}

.new_input_phone::placeholder {
  color: #c7c6c7;
}

.new_input_phone:focus {
  /* box-shadow: none !important; */
  border: 1px solid rgb(103, 124, 157) !important;
  border-radius: 4px !important;
  /* padding: 8px !important; */
  outline: none !important;
  font-weight: 500 !important;
  box-sizing: border-box !important;
  width: 100% !important;
  position: relative !important;
}

.new_input_label {
  font-size: 12px;
  color: #555;
  font-weight: 500;
}

.new_input_label1 {
  font-size: 12px;
  font-weight: 500;
}

.marque_section {
  -webkit-mask-image: linear-gradient(90deg, #0000, #fff 5%, #fff 95%, #0000);
}

.marque_card:hover {
  box-shadow: 0 0 1px #181d263d, 0 6px 8px #181d2614, 0 18px 32px #0000001a;
  border-radius: 8px;
}

/* sticky sidebar */
.sidebar {
  flex: 0 0 33.3333%;
  background: gray;
}

.sidebar__widget {
  position: sticky;
  top: 0;
  background: yellow;
  height: 250px;
}

.market-border::before {
  background: #fd181a;
  content: "";
  position: absolute;
  top: 0;
  height: 3px;
  opacity: 0;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  left: 2px;
  right: 2px;
}

.active2::before {
  background: #fd181a;
  content: "";
  position: absolute;
  top: 0;
  height: 4px;
  opacity: 1;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  left: 2px;
  right: 2px;
  border-radius: 20px;
}

.active2::after {
  width: 20px;
  height: 20px;
  background: #fff;
  content: "";
  position: absolute;
  bottom: -11px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  border-color: #fd181a;
  opacity: 1;
}

.active2 {
  border: 1px solid #fd181a;
  border-radius: 10px;
}

.img1 {
  position: absolute;
  left: -70px;
  top: -156px;
  width: 100px;
  height: 100px;
}

.img2 {
  position: absolute;
  right: -110px;
  top: -156px;
  width: 100px;
  height: 100px;
}

.img3 {
  position: absolute;
  left: -25px;
  width: 100px;
  height: 100px;
  top: 100px;
}

.img4 {
  position: absolute;
  right: -65px;
  top: 100px;
  width: 100px;
  height: 100px;
}

.connect-heading-line {
  position: relative;
  width: 100%;
  height: 1px;
  background-image: linear-gradient(to right, #fff 50%, transparent 50%);
  background-repeat: repeat-x;
  background-size: 8px 4px;
}

.connect-heading-line2 {
  position: relative;
  width: 100%;
  height: 1px;
  background-image: linear-gradient(to right, #fff 50%, transparent 50%);
  background-repeat: repeat-x;
  background-size: 8px 4px;
}

.connect-heading-line::before {
  position: absolute;
  top: 50%;
  left: -8px;
  content: "";
  width: 4px;
  height: 4px;
  transform: translateY(-50%);
  border-radius: 50%;
  background: #fff;
}

.connect-heading-line::before {
  position: absolute;
  top: 50%;
  left: -8px;
  content: "";
  width: 4px;
  height: 4px;
  transform: translateY(-50%);
  border-radius: 50%;
  background: #fff;
}

.connect-heading-line::after {
  position: absolute;
  top: 50%;
  left: -8px;
  content: "";
  width: 10px;
  height: 10px;
  transform: translateY(-50%);
  border-radius: 50%;
  background: #05bdba;
  opacity: 0;
  animation: connectHeadingDotLeft 5s infinite linear;
}

.connect-heading-line[data-line-position="right"]::after {
  animation: connectHeadingDotRight 5s infinite linear;
}

@keyframes connectHeadingDotLeft {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 1;
    left: 95%;
  }

  51% {
    opacity: 1;
    left: 95%;
  }

  55% {
    opacity: 0;
    left: 95%;
  }
}

@keyframes connectHeadingDotRight {
  49% {
    opacity: 0;
  }

  50% {
    opacity: 1;
    left: -8px;
  }

  95% {
    opacity: 1;
    left: 100%;
  }

  96% {
    opacity: 1;
    left: 100%;
  }

  100% {
    opacity: 0;
    left: 100%;
  }
}

body .cursor-pointer {
  cursor: pointer !important;
}

.stripe_cards:hover .stripe_card_title {
  display: none;
}

.stripe_cards .stripe_card_btn {
  display: none;
}

.stripe_cards:hover .stripe_card_btn {
  display: block;
  transition: opacity 400ms ease 0s;
}

.stepper-dots {
  background-color: #d9d9d9;
  border: 2px solid #d9d9d9;
  height: 10px;
  width: 10px;
}

.stepper-plus-icon {
  --saf-0: rgba(var(--sk_foreground_low_solid, 221, 221, 221), 1);
  align-items: center;
  background-color: rgba(var(--sk_primary_background, 255, 255, 255), 1);
  border: 2px solid var(--saf-0);
  display: flex;
  height: 35px;
  justify-content: center;
  min-width: 0;
  opacity: 0;
  padding: 0;
  pointer-events: none;
  transition: opacity 80ms linear;
  width: 35px;
}

.stpper-lines {
  background-color: initial;
  height: 0;
  position: absolute;
  transition: background-color 0.16s cubic-bezier(0.36, 0.19, 0.29, 1),
    height 0.16s cubic-bezier(0.36, 0.19, 0.29, 1);
  width: 100%;
  z-index: 1;
}

.stepper-spacer {
  background-color: #d9d9d9;
  height: 24px;
  transition: height 0.16s cubic-bezier(0.36, 0.19, 0.29, 1);
  width: 4px;
}

.stepper-plus-icon:focus,
.stepper-plus-icon:hover,
.stepper-container:hover .stepper-plus-icon {
  border-radius: 100%;
  opacity: 1;
  pointer-events: all;
}

.stepper-plus-icon:focus,
.stepper-plus-icon:hover {
  background-color: #f8f8f8;
  pointer-events: all;
}

.stepper-plus-icon:focus + .stpper-lines,
.stepper-plus-icon:hover + .stpper-lines {
  background-color: #0057ff;
  height: 2px;
}

.stepper-plus-icon:hover {
  background: #f8f8f8;
  box-shadow: 0 1px 3px 0 #00000014;
}

.stepper-plus-icon:focus ~ .stepper-spacer,
.stepper-plus-icon:hover ~ .stepper-spacer {
  height: 34px;
}

.stripe-int-icon {
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #e5e5e5;
  border-radius: 15px;
}

.stripe-int-icon-1 {
  height: 70px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #e5e5e5;
  border-radius: 15px;
}

.article_left_accordion .accordion__panel {
  padding: 0 17px 13px 20px !important;
}

.article_pageslidebar .accordion__panel {
  padding: 0 17px 13px 20px !important;
}

/* .article_pageslidebar .accordion:nth-child(8) .accordion__button::before{
  display: none;
} */
@media screen and (max-width: 768px) {
  /* Your CSS rules for phones go here */
  .my-page {
    overflow-y: scroll;
  }
}

/* START:: For Learning Center Side Animation */
@keyframes side-drawer-opacity-animation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes external__content__progress-bar-pulse {
  0% {
    background-color: #222;
  }

  25% {
    background-color: #434343;
  }

  50% {
    background-color: #e8e8e8;
  }

  75% {
    background-color: #757575;
  }

  100% {
    background-color: #434343;
  }
}

.rightSlideAnimations {
  animation: side-drawer-opacity-animation 0.3s ease-in-out;
}

.blink-in-progress-animation {
  animation-name: external__content__progress-bar-pulse;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  transition: all 1s;
  border-radius: 10px;
}

.rhap_container .rhap_main.rhap_stacked {
  flex-direction: row;
}

.rhap_container .rhap_stacked .rhap_controls-section {
  margin-top: 0px;
}

@media (min-width: 991px) {
  .rightsidebar_width {
    width: calc(100% - 350px);
  }

  .table_overflow {
    overflow: unset;
  }
}

@media screen and (min-width: 641px) and (max-width: 1258px) {
  .integrationspopup {
    width: calc(100% - 256px);
  }
}

@media (min-width: 1259px) {
  .integrationspopup {
    width: 1000px;
  }
}

@media (max-width: 640px) {
  .integrationspopup {
    width: 100%;
  }
}

@media (max-width: 990px) {
  .rightsidebar_width {
    width: auto;
  }

  .table_overflow {
    overflow-x: scroll !important;
  }

  body .hide-body {
    overflow: hidden !important;
  }

  .headerAlignment {
    display: -webkit-inline-box !important;
  }
}

.forBoredr div {
  border-right: 1px solid #d9d9d9 !important;
  border: 1px solid #d9d9d9 !important;
  padding: 8px 14px !important;
}

.forBoredrbtm {
  border: 1px solid #d9d9d9 !important;
  padding: 8px 15px !important;
}

.fXTWVm {
  border: 0.5px dashed #2196f3 !important;
}

.admin-sidebar-wrapper:hover .admin-sidebar-logo {
  opacity: 0;
}

.admin-sidebar-wrapper:hover .admin-sidebar-title {
  margin-left: 0px;
}

.admin-sidebar-title {
  transition: margin-left 0.15s ease 0s;
  margin-left: 37px;
}

body .rhap_container {
  box-shadow: none !important;
  background-color: #fff;
}

body .rhap_time {
  font-size: 12px !important;
}

body .rhap_progress-filled {
  background-color: #0057ff !important;
}

body .rhap_main-controls-button {
  color: #0057ff;
}

.closeIcon {
  cursor: pointer;
  float: none;
  height: 13px;
  margin-left: 0;
}

body .rhap_play-pause-button {
  font-size: 20px;
  width: 24px;
  height: 29px;
  margin-left: 10px;
}

body .rhap_additional-controls {
  display: none;
}
body .rhap_download-progress {
  background-color: #dddddd36;
}
body .rhap_progress-indicator {
  box-sizing: border-box;
  position: absolute;
  z-index: 3;
  width: 17px;
  height: 17px;
  margin-left: -10px;
  top: -6px;
  background: #0057ff;
  border-radius: 50px;
  box-shadow: rgba(134, 134, 134, 0.5) 0 0 5px;
}

body .rhap_controls-section {
  flex: unset;
}

div[data-column-id="Role"] {
  display: flex;
  justify-content: center;
}

div[data-column-id="used"] {
  display: flex;
  justify-content: center;
}
div[data-column-id="question"] {
  min-width: 45%;
}
div[data-column-id="created"] {
  display: flex;
}


div[data-column-id="term"] {
  width: 67px;
  white-space: break-spaces;
  text-align: center;
}

div[data-column-id="Action"] {
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  div[data-column-id="Role"] {
    display: none;
  }

  div[data-column-id="Action"] {
    display: flex;
    justify-content: start !important;
  }
}

@media (min-width: 768px) {
  .centered-table .rdt_TableCell {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .centered-table .rdt_TableCol_Sortable {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* new table css */

.limiter {
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

.wrap-table100 {
  width: 100%;
}

.wrap-table100 table {
  border-spacing: 1;
  border-collapse: collapse;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.wrap-table100 table tbody tr {
  height: 50px;
}

.wrap-table100 table td,
.wrap-table100 table th {
  text-align: left;
  padding-left: 8px;
}

.wrap-table100 .column1 {
  width: 260px;
  padding-left: 40px;
}

.wrap-table100 .column2 {
  width: 160px;
}

.wrap-table100 .column3 {
  width: 245px;
}

.wrap-table100 .column4 {
  width: 110px;
  text-align: right;
}

.wrap-table100 .column5 {
  width: 170px;
  text-align: right;
}

.wrap-table100 .column6 {
  width: 222px;
  text-align: right;
  padding-right: 62px;
}

.wrap-table100 tbody tr:hover {
  color: #555;
  background-color: #f5f5f5;
  cursor: pointer;
}

.wrap-table100 tbody tr:nth-child(2n) {
  background-color: #f5f5f5;
}

@media screen and (max-width: 992px) {
  .wrap-table100 .wrap-table100 {
    width: 100%;
  }

  .wrap-table100 table {
    border-spacing: 1;
    border-collapse: collapse;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }

  .wrap-table100 table thead {
    display: none;
  }

  /* .wrap-table100 table tbody tr {
    height: auto;
    padding-top: 37px;
    display: block;
  } */
  .wrap-table100 table tbody tr {
    height: 183px;
    padding-top: 30px;
    display: block;
    padding-bottom: 1px;
    margin-bottom: 12px;
    border-radius: 10px;
  }

  .wrap-table100 table tbody tr td::before {
    font-size: 14px;
    color: #999;
    line-height: 1.2;
    font-weight: 700;
    position: absolute;
    left: 30px;
  }

  .wrap-table100 .column4,
  .wrap-table100 .column5,
  .wrap-table100 .column6,
  .wrap-table100 .column1,
  .wrap-table100 .column2,
  .wrap-table100 .column3 {
    width: 100%;
    text-align: left !important;
    position: relative;
  }

  .wrap-table100 table tbody tr td {
    padding-left: 110px !important;
    margin-bottom: 24px;
    display: block;
    text-align: left !important;
  }

  .wrap-table100 table tbody tr td:nth-child(1)::before {
    content: "Email";
  }

  .wrap-table100 table tbody tr td:nth-child(2)::before {
    content: "Action";
  }

  .wrap-table100 table tbody tr td:nth-child(3)::before {
    content: "Name";
  }

  .wrap-table100 table tbody tr td:nth-child(4)::before {
    content: "Contact";
  }

  .wrap-table100 table tbody tr td:nth-child(5)::before {
    content: "Role";
  }

  .wrap-table100 table tbody tr td:nth-child(6)::before {
    content: "Action";
  }
}

textarea#answer,
textarea#negative_answer,
textarea#workflow_answer {
  width: 100%;
  border: 1px solid #00000059;
  padding: 3px;
  border-radius: 3px;
}

body .rdt_Pagination select:focus {
  border: 1px solid rgb(19 95 194) !important;
}

body .rdt_Pagination select {
  border: 1px solid transparent !important;
  font-size: 12px !important;
}

body .rdt_Pagination {
  font-size: 12px !important;
  padding-right: 19px !important;
}

.knowledge .rdt_Pagination {
  font-size: 12px !important;
  /* margin-left: 31px !important; */
}

.rdt_Pagination {
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border-color: #dbe1e8;
}
.logss .rdt_Pagination {
  font-size: 12px !important;
  /* margin-left: 31px !important; */
}
.member_table .rdt_Pagination {
  border-left: 0px solid;
  border-right: 0px solid;
  border-radius: 0px;
}
.data-table-class {
  min-height: 50vh !important;
}

.basic-knowledge .rdt_TableRow {
  padding: 0 !important;
  height: 55px !important;
}

.tbsearchbar::-webkit-input-placeholder {
  padding-top: 5px;
}

.testbotwidget {
  bottom: 75px;
  right: 20px;
}

@media (max-width: 768px) {
  .testbotwidget {
    bottom: 0px;
    right: 0px;
    top: 0px;
    left: 0px;
  }

  /* .knowledgebase_table .sc-feUZmu.bSqufN {
    max-height: 60vh !important;
  } */
}

input[type="checkbox"].custom-checkbox {
  position: relative;
  border: 2px solid #2563eb;
  border-radius: 2px;
  background: none;
  cursor: pointer;
  line-height: 0;
  outline: 0;
  padding: 0 !important;
  vertical-align: text-top;
  height: 16px;
  width: 32px;
  -webkit-appearance: none;
  opacity: 0.8;
}

input[type="checkbox"].custom-checkbox:hover {
  opacity: 1;
}

input[type="checkbox"].custom-checkbox:checked {
  background-color: #2563eb;
  opacity: 1;
}

input[type="checkbox"].custom-checkbox:before {
  content: "";
  position: absolute;
  right: 50%;
  top: 50%;
  width: 5px;
  height: 9px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  margin: -1px -1px 0 -1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
}

.checkout-dropdown {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background: url(http://dropdown/arrow/url/) no-repeat;
  background-position: right center;
  background-color: #fff;
}

.learning-class .rdt_Table {
  height: 70vh;
}

.invite-email input:focus {
  border: none !important;
}

body .invite-email input::placeholder,
.invite-email input,
body
  .invite-email
  input[type="text"]
  body
  .invite-email
  input[type="text"]:focus,
body .invite-email input[type="email"]:focus,
.invite-email input[type="number"]:focus,
.invite-email input[type="password"]:focus,
.invite-email input[type="date"]:focus,
.invite-email input[type="search"]:focus,
.invite-email textarea:focus {
  font-size: 0.875rem !important;
}

.boredractive {
  position: relative;
  border-bottom: 2px solid #0057ff;
  box-sizing: border-box;
  color: #0057ff;
  padding-bottom: 3px;
}

.boredrinactive {
  position: relative;
  border-bottom: 2px solid transparent;
  box-sizing: border-box;
  color: #000;
  padding-bottom: 3px;
}

.bi-infinity {
  font-size: 20px;
}

@media (max-width: 991px) {
}

@media screen and (max-width: 600px) {
  #chatbot_widget .chat_content {
    height: 70vh !important;
  }

  .sidebar-wrapper-scroller ul li,
  .sidebar-wrapper-scroller ul li:focus,
  .sidebar-wrapper-scroller ul li:focus-visible,
  .sidebar-wrapper-scroller ul li a,
  .sidebar-wrapper-scroller ul li a:focus-visible,
  .sidebar-wrapper-scroller ul li a:focus {
    background: transparent !important;
    background-color: transparent !important;
  }

  body .sidebar-wrapper-scroller .bg-sidebar-hover {
    background: radial-gradient(
      400% 240% at -170% 75%,
      rgb(153, 0, 102) 10%,
      rgb(255, 82, 51) 50%,
      rgb(255, 149, 0) 90%
    ) !important;
  }
}

.bg-gradiant-red-button {
  background: radial-gradient(
    400% 240% at -170% 75%,
    rgb(204, 0, 0) 10%,
    rgb(246, 48, 48) 50%,
    rgb(255, 104, 84) 90%
  ) !important;
}

.bg-gradient-blue-button {
  background: radial-gradient(
    400% 240% at -170% 75%,
    rgb(0, 0, 204) 10%,
    rgb(48, 48, 246) 50%,
    rgb(84, 104, 255) 90%
  ) !important;
}

.bg-gradient-black-button {
  background: radial-gradient(
    400% 240% at -170% 75%,
    rgb(0, 0, 0) 10%,
    rgb(64, 64, 64) 50%,
    rgb(128, 128, 128) 90%
  ) !important;
}

.see:before {
  background: #ff5722;
  width: 0;
  height: 2px;
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  transition: 0.2s;
}

body video {
  height: fit-content !important;
  position: absolute !important;
  top: 25% !important;
}

.modal-open {
  overflow: hidden;
}

#chatbot_widget .containerChatBot_entire {
  top: auto !important;
}

body .custome_table {
  overflow-x: hidden !important;
}

.video_popup {
  margin: auto;
  height: auto;
  position: relative;
}

.video_popup_cross {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}

.video_popup_cross svg {
  background: transparent;
  padding: 4px;
  color: #fff;
}

.drag {
  display: flex;
  flex-direction: column-reverse;
}

.privacy_link_footer {
  border-right: 0px !important;
}

@media screen and (max-width: 1065px) {
  .popup_z_index {
    z-index: 10 !important;
  }
}

.for-bg:before {
  content: "";
  background: linear-gradient(0deg, currentColor, hsla(0, 0%, 100%, 0));
  position: absolute;
  width: 100%;
  height: 100%;
}

.menu_link_main:hover .menu_link_arrow {
  opacity: 1;
  right: 0;
}

.menu_link_arrow {
  width: 23px;
  opacity: 0;
  transition: all 0.2s;
  position: absolute;
  top: 50%;
  bottom: auto;
  left: auto;
  right: 6px;
  transform: translateY(-50%);
}

@media (max-width: 991px) {
  .tdline p {
    width: auto;
    max-width: 170px;
    white-space: break-spaces;
    font-size: 13px;
    display: inline-block;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: normal;
    line-break: strict;
    hyphens: none;
    -webkit-hyphens: none;
    -moz-hyphens: none;
  }
}

/* body input[type="text"]:focus,
body input[type="number"]:focus,
body input[type="date"]:focus,
body input[type="email"]:focus,
body input[type="password"]:focus,
body select:focus,
body textarea:focus {
  border: none !important;
  outline: none !important;
line-height: initial;
} */

/* slider */
.chatfeature_slider {
  position: relative;
}

.chatfeature_slider button.slick-arrow.slick-prev::before,
.chatfeature_slider button.slick-arrow.slick-next::before {
  display: none;
}

.chatfeature_slider button.slick-arrow.slick-prev {
  background-image: url("/feature-slider/arrow_down.webp");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 50px;
  left: unset;
  right: 43px;
  width: 50px;
  top: 300px;
}

.chatfeature_slider button.slick-arrow.slick-next {
  background-image: url("/feature-slider/arrow_up.webp");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 50px;
  right: -10px;
  width: 50px;
  top: 300px;
}

.chatfeature_slider .slick-dots {
  display: flex !important;
  width: 87%;
  position: relative;
  top: 34px;
}

.chatfeature_slider .slick-dots li button {
  width: 80%;
}

.chatfeature_slider .slick-dots li button {
  background: transparent;
  border-radius: 25px;
  height: 4px;
  padding: 0;
  width: 100%;
}

.chatfeature_slider .slick-dots li {
  width: 80%;
}

.chatfeature_slider .slick-dots li button::before,
.hero_slider .slick-dots li.slick-active button::before {
  color: transparent;
  width: 100%;
}

.chatfeature_slider .slick-dots li.slick-active button {
  background: #fff;
}

.animate_lottie {
  top: -26px;
}

.animate_lottie .wrap_player {
  height: 600px;
  width: 620px;
}

@media screen and (min-width: 530px) and (max-width: 583px) {
  .icons.pb-12.w-full.mx-auto.mt-5 {
    padding-top: 2rem;
  }
}
@media screen and (min-width: 584px) and (max-width: 639px) {
  .icons.pb-12.w-full.mx-auto.mt-5 {
    padding-top: 3rem;
  }
}
@media screen and (max-width: 450px) {
  .animate_lottie {
    top: 7px;
  }
}
@media screen and (max-width: 768px) {
  /* .animate_lottie {
    top: 0px;
  } */

  .animate_lottie .wrap_player {
    width: 100%;
  }

  .chatfeature_slider .slick-dots {
    width: 77%;
    top: 25px;
  }
}

body input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.StripeElement {
  border-radius: 0px !important;
  border: none !important;
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.p-FieldLabel {
  font-size: 14px !important;
}

.Label {
  display: none !important;
}

.capitalize-first-line::first-letter {
  text-transform: capitalize;
}

::-webkit-scrollbar {
  width: 8px;
  /* Ancho de la scrollbar */
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  /* Color de fondo de la pista de la scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: rgb(213, 213, 213);
  /* Color del pulgar de la scrollbar */
  border-radius: 6px;
  /* Bordes redondeados del pulgar */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #c0c0c0;
  /* Color del pulgar cuando se pasa el mouse sobre él */
}

.home_widgets_box .cm-content {
  flex-shrink: unset !important;
  white-space: unset !important;
  overflow: visible !important;
}

.home_widgets_box .cm-scroller {
  overflow: visible !important;
}

.schedule::-webkit-scrollbar-thumb {
  /* background-color: #ff5233; */
  /* Change this to your desired scrollbar thumb color */
  border-radius: 10px;
}

/* Scrollbar track (the background) */
.schedule::-webkit-scrollbar-track {
  background-color: transparent !important;
  /* Change this to your desired scrollbar track color */
}

/* Optional: Adjust scrollbar width and height */
.schedule::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

@media screen and (max-width: 768px) {
  .negative-term-search-table .sc-hzhJZQ.gmlwXk {
    display: block !important;
  }

  .negative-term-search-table .keyword_container {
    gap: 0rem !important;
    width: 100% !important;
  }
}

.history_logs_wrapper img {
  width: 200px !important;
  height: 200px !important;
  margin-top: 15px;
  object-fit: contain;
}

.icon:hover {
  transform: rotate(45deg);
  transition-duration: 0.5s;
}
.icon1:hover {
  transform: rotate(45deg);
  transition-duration: 0.5s;
}

.tippy-box {
  background-color: #fff !important;
  color: #000 !important;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 0px 5px 0px;
  font-size: 12px !important;
  font-weight: 500;
}

.tippy-arrow:before {
  color: #fff;
}

.chart-animation {
  animation: slide-down 0.5s ease-in-out; /* Apply the custom slide-down animation */
}
input#domainName {
  border: 1px solid #d5dbe7 !important;
}
.myDataTable {
  /* max-height: 120vh !important; */
  max-height: unset !important;
  height: auto !important;
}

.myDataTable .rdt_Table.rdt_TableHead {
  border: none !important;
}

.myDataTable .rdt_Table.rdt_TableHeadRow {
  border: none !important;
}

.myDataTable .rdt_Table .rdt_TableRow {
  border: 1px solid;
  border-bottom: 0;
  border-color: #dbe1e8;
  /* height: 5rem !important; */
}
.myDataTable .rdt_Table .rdt_TableRow:last-child {
  /* border-bottom: 1px solid ; */
  /* border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px; */
  border-color: #dbe1e8;
}
.myDataTable .rdt_Table .rdt_TableRow:first-child {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  border-color: #dbe1e8;
}

.myDataTable .rdt_Table .rdt_thead {
  border-bottom: none;
}
.myDataTable .rdt_TableHeadRow:first-child {
  border: none;
}

.searchWrap .searchWrapper {
  border: none;
  /* width: 120%; */
  width: auto;
  display: flex;
  gap: 7px;
}

.myDataTable .chip {
  font-weight: 400;
  /* width: 69%; */
  border-radius: 5px;
  background: #2563ebbf !important;
  color: #fff;
  /* width: 92%; */
  width: auto !important;
  display: flex;
  flex: none;
}

.myDataTable span.chip.false.false {
  position: relative;
}

.myDataTable .closeIcon {
  /* display: none; */
}

.myDataTable .rdt_Table .rdt_TableRow:hover {
  outline: 1px solid #2563eb;
  outline-offset: -1px;

  background-color: white;
}
/* Scrollbar Styling */

/* width */
.email-scroll::-webkit-scrollbar {
  width: 2px !important;
  height: 5px;
}

/* Track */
.email-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
.email-scroll::-webkit-scrollbar-thumb {
  background: red;
  width: 2px !important;
  height: 5px;
  border-radius: 5px;
}

/* Handle on hover */
.email-scroll::-webkit-scrollbar-thumb:hover {
  border-radius: 5px;

  background: red;
  width: 2px !important;
  height: 5px;
}

/* @media screen and (min-width:992px) and (max-width:1199px){
  .myDataTable p.whitespace-normal.p-2.ml-\[35px\] {
    padding: 0;
    margin: 0;
    width: 40px;
}
.myDataTable .rdt_Table .rdt_TableRow { 
  width: 83%;
  }
  .myDataTable .searchWrap .searchWrapper {  
    display: block; 
    background: transparent;
}}

@media screen and (min-width:800px) and (max-width:991px){
  .myDataTable p.whitespace-normal.p-2.ml-\[35px\] {
    padding: 0;
    margin: 0;
    width: 40px;
}
.myDataTable .rdt_TableCol { 
  min-width: 180px;
}
.myDataTable .rdt_TableCell { 
min-width: none; 
min-width: auto;padding:0;
}
.myDataTable .searchWrap .searchWrapper { 
display: block; background:transparent;
}
.myDataTable .rdt_Table .rdt_TableRow { 
width: 65%;
}
} */

@media screen and (min-width: 640px) and (max-width: 1199px) {
  .myDataTable {
    overflow-x: scroll !important;
  }
  .myDataTable .rdt_TableCell {
    position: relative;
    right: 20px;
  }
}

.searchWrap::-webkit-scrollbar {
  width: 10px;
  height: 4px;
}

.searchWrap::-webkit-scrollbar-track {
  background: white;
}

.searchWrap::-webkit-scrollbar-thumb {
  background: rgb(217, 215, 215);
  border-radius: 12px;
}
.test-ideo {
  height: 400px;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background-color: rgba(203, 206, 209, 0.2);
  border-radius: 5px;
  padding: 10px;
}

body #chatbot_widget .chat_content_embed {
  min-height: 200px !important;
}

body #chatbot_widget .tempoWidget-suggestedQuestions-option {
  padding: 3px 15px !important;
  font-size: 12px !important;
}

body #chatbot_widget .answer_text {
  padding: 3px 15px !important;
  font-size: 12px !important;
}

@media screen and (max-width: 767px) {
  .widge .widget_container\.active {
    height: 52vh !important;
  }
  .checkout-card-logos {
    gap: 0.4rem;
    flex-wrap: wrap;
    justify-content: center !important;
  }

  .custom-margin-left-btn-0 {
    margin-left: 5px;
  }

  .custom-margin-left-btn-1 {
    margin-left: 10px;
  }

  .custom-margin-left-btn-2 {
    margin-left: 50px;
    margin-bottom: 2rem;
  }
  .custom-margin-left-btn-3 {
    margin-left: 50px;
    margin-bottom: 2rem;
  }
  #link-checkbox {
    width: 50px;
  }
}

@media screen and (min-width: 768px) {
  .myDataTable .rdt_Table .rdt_TableRow {
    padding-left: 8px;
  }
  .shadow-custom-checkout {
    box-shadow: 0 13px 27px -5px rgba(245, 69, 92, 0.25),
      0 8px 16px -8px rgba(0, 0, 0, 0.3);
  }

  body #link-checkbox {
    width: 30px;
  }
}

.checkout-card-logos {
  display: flex;
  width: 40%;
  justify-content: space-evenly;
}
.border-css {
  border-radius: var(--border-radius-lg, 10px);
}
.pink_gradient_text p{
  background: linear-gradient(
    86deg,
    rgba(202, 89, 134, 0.1) -4.01%,
    rgba(115, 22, 170, 0.1) 102.45%
  );
  background: var(
    --Gradient,
    linear-gradient(86deg, #ca5986 -4.01%, #7316aa 102.45%)
  );
  background-clip: text !important;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.pink_gradient_text {
  font-size: 14px;
  border-radius: 10px;
  border: 1px solid #ca5986;
  display: flex;
  padding: 2px 6px 3px 6px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background: linear-gradient(86deg, rgba(202, 89, 134, 0.09) -4.01%, rgba(115, 22, 170, 0.09) 102.45%);
}
.modalUser .special-label{
  display: none !important;
}
.modalUser input.lg-form-control.form-border{
  padding-left: 0px !important;
}
.rdt_Pagination button#pagination-last-page {
  display: none !important;
}
.rdt_Pagination button#pagination-first-page {
  display: none !important;
}
body .rdt_Pagination {
  justify-content: center;
  gap: 12px !important;
  font-weight: 700 !important;
}
/* body .rdt_Pagination .fimDOL {
  width: 80%;
  justify-content: right;
} */
body .rdt_Pagination button {
  background: white;
  padding: 4px 10px;
  display: inline-block;
  border-radius: 4px;
  margin-left: 10px;
  width: auto;
  height: auto;
  border: 1px solid #808080a8;
}




body .rdt_Pagination button:disabled {
  background: #ececec85;
  border: none;
}


.modalUser .special-label{
  display: none !important;
}

.modalUser input.lg-form-control.form-border{
  padding-left: 0px !important;
}
::-webkit-scrollbar{
  height: 8px;
  width: 8px;
}

input#link-checkbox-checkout {
  top: 3px;
}
input#link-checkbox-checkout {
  /* width: 39px; */
}
@media screen and (min-width:1299px) and (max-width:1535px){
  input#link-checkbox-checkout {
    /* width: 39px; */
}}
@media screen and (min-width:1024px) and (max-width:1298px){
  input#link-checkbox-checkout {
    /* width: 52px; */
}}
@media screen and (min-width:768px) and (max-width:1023px){
  input#link-checkbox-checkout {
    /* width:100px; */
}}
@media screen and (min-width:320px) and (max-width:767px){
  input#link-checkbox-checkout {
    /* width:50px; */
}}

.quick-start {
  /* Hide scrollbar for Chrome, Safari and Opera */
  overflow-y: scroll;
  scrollbar-width: none; /* For Firefox */
}

/* Hide scrollbar for IE, Edge and Chrome */
.quick-start::-webkit-scrollbar {
  display: none;
}

/* Dashboard.css */
.hide-scroll-for-dashboard-home {
  overflow: hidden !important;
}

.hide-scroll-for-dashboard-home * {
  scrollbar-width: none !important; /* For Firefox */
  -ms-overflow-style: none !important; /* For Internet Explorer and Edge */
}

.hide-scroll-for-dashboard-home *::-webkit-scrollbar {
  display: none !important; /* For Chrome, Safari, and Opera */
}

.loader {
  width: 108px;
  height: 16px;
  background:
    radial-gradient(circle 8px at 8px center, white 100%, transparent 0),
    radial-gradient(circle 8px at 8px center, white 100%, transparent 0);
  background-size: 16px 16px;
  background-repeat: no-repeat;
  animation: ballX 1s linear infinite;
  position: fixed;
  z-index: 999999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loader:before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background:#F5455C;
  inset:0;
  margin:auto;
  animation: moveX 1s cubic-bezier(0.5,300,0.5,-300) infinite;
}
@keyframes ballX {
  0%,25%,50%,75%, 100%  {background-position: 25% 0,75% 0}
  40%     {background-position: 25% 0,85% 0}
  90%     {background-position: 15% 0,75% 0}
}
@keyframes moveX {
  100% {transform:translate(0.15px)}
}





@media screen and (min-width:320px) and (max-width:1023px){
  body .attach-form-data {
      padding: 20px 30px !important;
  }
  body input#link-checkbox-checkout {
    /* width: 100px !important; */
}
  }
  input#link-checkbox-checkout {
    min-width: 16px;
}
/* Add this in your global CSS file */
body .input-placeholder-lg-xx input::placeholder {
  font-size: 20px !important; /* This is equivalent to text-lg in Tailwind */
  font-weight: 500; /* This is equivalent to font-medium in Tailwind */
  color:#a0a0a9;
}

body .input-placeholder-lg-xx input:focus {
  font-size: 20px !important; /* This is equivalent to text-lg in Tailwind */

}
body .input-placeholder-lg-xx input {
  font-size: 20px !important; /* This is equivalent to text-lg in Tailwind */
padding-left: 45px;
}
@media only screen and (max-width: 768px) {
  .input-placeholder-lg-xx input {
      font-size: 16px !important; /* This is equivalent to text-lg in Tailwind */
      padding-left: 45px;
  }
}
.get-start  input[type="text"], .get-start input[type="email"],.get-start input[type="number"],
.get-start input[type="date"],.get-start input[type="password"],.get-start input[type="search"],.get-start textarea {
 padding-left: 0px;
 font-size: 18px !important;
}
.get-start select{
  padding-left: 0px;
  font-size: 16px !important;
  font-weight: 300 !important;
}

.get-start input::-ms-input-placeholder { /* Edge 12-18 */
  color: #797575;
  font-size: 18px !important;
}

.get-start  input[type="text"]:focus, .get-start input[type="email"]:focus,.get-start input[type="number"]:focus,
.get-start input[type="date"]:focus,.get-start input[type="password"]:focus,.get-start input[type="search"]:focus,.get-start textarea {
  color: #797575;
  font-size: 18px !important;
}
.get-start  input[type="text"]::placeholder, .get-start input[type="email"]::placeholder,.get-start input[type="number"]::placeholder,
.get-start input[type="date"]::placeholder,.get-start input[type="password"]::placeholder,.get-start input[type="search"]::placeholder,.get-start textarea {
  color: #797575;
  font-size: 18px !important;
}

/* START:: Chervon Animation */

.chervon-animation {
  -webkit-clip-path: polygon(0 0, 80% 0, 100% 50%, 80% 100%, 0 100%, 20% 50%);
  clip-path: polygon(0 0, 80% 0, 100% 50%, 80% 100%, 0 100%, 20% 50%);
}
.chervon-animation-right {
  -webkit-clip-path: polygon(0% 0%, 80% 0%, 100% 50%, 80% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 80% 0%, 100% 50%, 80% 100%, 0% 100%);
}